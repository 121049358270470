import Button from '@mui/material/Button';
import "../global.css"

export function StyledButton(prop: { text: string, target: string }) {
    return (
        <div style={{ width: "100%", display: "flex", margin: 'auto', justifyContent: 'center', padding: 10 }}>
            <a target="_blank" className={"styledButton"} href={prop.target} style={{ borderRadius: 5, borderWidth: 1, borderColor: "black", paddingTop: 5, paddingBottom: 5, paddingLeft: 15, paddingRight: 15, textDecoration: "none" }}>
                <p>{prop.text.toUpperCase()}</p>
            </a>
        </div >
    )
}