import resumeData from "../assets/resume.json"
import "../global.css"
import { StyledButton } from "./StyledButton"

export function Resume() {
    return (
        <div style={{ margin: "auto", width: "100%", maxWidth: 900, }}>
            {resumeData.map((item, key) => {
                return (<div style={{ padding: 20, marginBottom: 30, borderWidth: 2, borderRadius: 20, justifyContent: "space-between", display: "flex", flexDirection: "row" }}>
                    <p style={{ fontSize: 14, width: "20%" }}>{item.timeframe}</p>
                    <div style={{ flexDirection: "column", marginLeft: 20, }}>
                        <h3>{item.title}</h3>
                        <h4>{item.company}</h4>
                        <div style={{ marginTop: 10 }}>
                            {item.detailsList.map((bullet, key) => {
                                return (<li style={{ fontWeight: "300", fontSize: 14 }}>{bullet}</li>)
                            })}
                        </div>
                    </div>
                </div>)
            })}
            <StyledButton text={"See Full Experience List"} target="https://www.linkedin.com/in/elysiasmyers/"></StyledButton>
        </div>

    )
}