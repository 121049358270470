import { useEffect, useMemo, useState } from 'react';
import portrait from "./assets/portrait.jpg"
import './App.css';
import { Resume } from './components/Resume';
import './global.css'
import { SectionHeader } from './components/SectionHeader';
import linkedin from "./assets/linkedin.png"
import email from "./assets/email.png"
import { Projects } from './components/Projects';

function App() {
  const [scrollPosition, setScrollPosition] = useState({
    fromTop: 0,
    fromBottom: document.documentElement.scrollHeight * 5 - window.innerHeight,
  });

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition({
        fromTop: position,
        fromBottom: document.documentElement.scrollHeight - window.innerHeight - position
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const fontSize = useMemo(() => {
    if (window.innerWidth < 600) {
      return Math.max(36 - scrollPosition.fromTop / 10, 20)
    }
    return Math.max(80 - scrollPosition.fromTop / 10, 40)
  }, [scrollPosition])
  const headerPos = useMemo(() => {
    return Math.max(60 - scrollPosition.fromTop / 10, 15)
  }, [scrollPosition])
  const bottomScroll = useMemo(() => {
    return Math.max(-150, 0 - scrollPosition.fromBottom / 5)
  }, [scrollPosition.fromBottom])
  return (
    <div className="content" >
      <div className="header-container" style={{ top: headerPos - bottomScroll - 150 }}>
        <a href="mailto:contact.eqs@gmail.com" target="_blank" rel="noreferrer">
          <img src={email} style={{ width: 40, height: 40 }} alt="email symbol"></img>
        </a>
        <a href="https://www.linkedin.com/in/elysiasmyers/" target="_blank" rel="noreferrer">
          <img src={linkedin} style={{ width: 40, height: 40 }} alt="linkedin logo"></img>
        </a>
      </div>
      <h2 className="home-subheader">
        Hello, my name is
      </h2>
      <h1 className="highlight" style={{ fontSize: `${fontSize}pt`, lineHeight: `${window.innerWidth < 600 ? 40 + 15 : fontSize + 15}pt`, position: "sticky", top: Math.min(0, headerPos - bottomScroll - 150), backgroundColor: "white", fontWeight: 400, whiteSpace: "nowrap", overflow: "hidden" }}>
        Elysia Smyers
      </h1>
      <div className="info-box">
        <h2 className="home-subheader">
          I build human-centered online systems.
        </h2>
        <p style={{ marginBottom: 10, marginTop: 40 }}>
          I graduated from Stanford University in March 2024 with a <b>BS in Symbolic Systems</b> and <b>MS in Computer Science</b>. Along the way, I've explored product management and operations management, but have developed a <b>true passion for engineering</b>. I love engaging in hands-on work designing and building systems that take into account users' needs at every step of the way.
        </p>
        <p style={{ marginBottom: 10 }}>
          Scroll to explore my <a href="#PROJECTS"><b>projects</b></a> and <a href="#EXPERIENCE"><b>experience</b></a>!
        </p>
      </div>
      <div style={{ marginTop: 20 }}>
        <SectionHeader title="PROJECTS" />
        <Projects />
      </div>
      <div style={{ marginTop: 20 }}>
        <SectionHeader title="EXPERIENCE" />
        <Resume />
      </div>
      <div style={{ height: 100 }}>
        <h1 style={{ fontSize: `20pt`, lineHeight: window.innerWidth < 600 ? '40pt' : '80pt', position: "fixed", bottom: bottomScroll, fontWeight: 400, whiteSpace: "nowrap", overflow: "hidden" }}>
          Elysia Smyers
        </h1>
        <div style={{ width: window.innerWidth < 600 ? 90 : 170, justifyContent: "space-between", display: "flex", flexDirection: "row", position: "fixed", bottom: bottomScroll + 20, zIndex: 1000, right: 20 }}>
          <a href="mailto:contact.eqs@gmail.com" target="_blank" rel="noreferrer">
            <img src={email} style={{ width: window.innerWidth < 600 ? 40 : 80, height: window.innerWidth < 600 ? 40 : 80 }} alt="email symbol"></img>
          </a>
          <a href="https://www.linkedin.com/in/elysiasmyers/" target="_blank" rel="noreferrer">
            <img src={linkedin} style={{ width: window.innerWidth < 600 ? 40 : 80, height: window.innerWidth < 600 ? 40 : 80 }} alt="linkedin logo"></img>
          </a>
        </div>
      </div>
    </ div >
  );
}

//http://web.stanford.edu/class/cs194h/projects/root/

export default App;
