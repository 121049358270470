import Grid from '@mui/material/Grid';
import { StyledButton } from "./StyledButton";

function Project(prop: {
    topMargin: number,
    title: string,
    description: string,
    link: string | undefined,
    images: any[] | undefined,
    background?: string
}) {
    return (
        <div style={{ backgroundColor: prop.background ?? "white", marginTop: prop.topMargin, paddingTop: 0, padding: 30 }}>
            <div style={{ flexDirection: "column", flex: "0 0 80%", alignContent: "center", display: "flex", alignItems: "center", margin: "auto", justifyContent: "center", maxWidth: 1200 }}>
                <h3 className="project-header">{prop.title}</h3>
                <p style={{ width: "70%", textAlign: "center" }}>{prop.description} </p>
                {prop.link &&
                    < StyledButton text={"Check it out"} target={prop.link}></StyledButton>}
                {prop.images}
            </div>
        </div >
    )
}


export function Projects() {
    return (
        <div style={{ margin: "auto", width: "100%" }}>
            <Project topMargin={0} title="root" description="React Native + Supabase application built as a final project for CS194H. 6-month project from needfinding through end-to-end execution. Awarded Best Visual Design." link="http://web.stanford.edu/class/cs194h/projects/root/" images={[
                <Grid container xs={12} spacing={2} style={{ marginTop: 10 }}>
                    <Grid item xs={4}>
                        <img src={require("../assets/root/home.png")} alt="root home screen" />
                    </Grid>
                    <Grid item xs={4} style={{ alignContent: "center" }}>
                        <img src={require("../assets/root/community.png")} alt="root community screen showing the Healthy Living community and associated user activities" />
                    </Grid>
                    <Grid item xs={4} style={{ alignContent: "center" }}>
                        <img src={require("../assets/root/profile.png")} alt="root profile tab showing a user's information and recent activities" />
                    </Grid>
                </Grid>

            ]} />
            <Project
                topMargin={60}
                title="AVOIDDS Benchmark"
                link="https://neurips.cc/virtual/2023/poster/73531"
                images={[
                    <Grid container xs={12} spacing={2} style={{ marginTop: 10 }}>
                        <Grid item xs={12}>
                            <img src={require("../assets/neurips.png")} alt="neurips poster for the AVOIDDS project" />
                        </Grid>
                    </Grid>
                ]}
                description="Open source benchmark repository and dataset for the vision-based aircraft detect-and-avoid problem. Created as part of the Stanford Intelligent Systems Laboratory and presented at NeurIPS 2023."
            />
            <Project
                topMargin={60}
                title="A Good Cause Productions"
                description="Website developed for A Good Cause Productions, a Los Angeles-based film production company and gear rental house. Built using Webflow."
                link="https://www.agoodcause.xyz/"
                images={[
                    <Grid container xs={12} spacing={2} style={{ marginTop: 10 }}>
                        <Grid item xs={12}>
                            <img src={require("../assets/agc/home.png")} alt="AGC home screen showing a still from a video" />
                        </Grid>
                        <Grid item xs={6} style={{ alignContent: "center" }}>
                            <img src={require("../assets/agc/speakeasyhome.png")} alt="AGC studio screen showcasing their production studio" />
                        </Grid>
                        <Grid item xs={6} style={{ alignContent: "center" }}>
                            <img src={require("../assets/agc/speakeasyphotos.png")} alt="AGC studio screen showing a gallery of the studio" />
                        </Grid>
                        <Grid item xs={12}>
                            <img src={require("../assets/agc/tabs.png")} alt="tabs on the AGC home screen that allow for navigation between areas of the site" />
                        </Grid>
                    </Grid>
                ]}
            />
            <Project
                title="eqsmy.github.io"
                description="This website, built using React by yours truly."
                topMargin={100}
                link={undefined}
                images={undefined}
            />
        </div>
    )
}